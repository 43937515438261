<template>
    <v-select 
        :multiple="multiple" 
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :placeholder="placeholder" />
</template>
<script>

import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/outbound/sto-processing/drivers",
            options: [],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {
        get() {
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "licence_number",
                        sort: "asc"
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        resp.data.records.map(function (x) {
                            return (x.label = x.licence_type + " / " + x.licence_number + " - " + x.name);
                        });

                        this.options = resp.data.records;
                    } else {
                        this.options = []
                        console.log(resp.data);
                    }
                });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        this.get();

    }
};
</script>